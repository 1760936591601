import {Box, Link} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ResponsiveAppBar from "../assets/components/ResponsiveAppBar.tsx";
import ContactFooter from "./ContactFooter.tsx";
import Footer from "./Footer.tsx";
import stickyNote from "../assets/flare/stickynote2.svg"
import React from "react";

interface LocationStickyProps {
    name: string,
    address1: string,
    address2: string,
    phone: string,
    email: string,
}
const LocationSticky: React.FC<LocationStickyProps> = ({name, address1, address2, phone, email}) =>{
    return <Box sx={{
        width: 300,
        height: 300,
        background: `url(${stickyNote})`,
        backgroundRepeat: "no-repeat",
        pt: 7,
        pr: 2,
        m: 5,
    }}>
        <Typography variant={"h6"} fontWeight={"bold"}>{name}</Typography>
        <Typography>{address1}</Typography>
        <Typography>{address2}</Typography>
        <Typography fontWeight={"bold"}><Link href={`tel:${phone}`}>{phone}</Link></Typography>
        <Typography fontWeight={"bold"}><Link href={`mailto:${email}`}>{email}</Link></Typography>
    </Box>;
}

function OurSchools() {
    return (
        <>
            <Box sx={{}}>
                <ResponsiveAppBar/>
                <Box className={'tile'} sx={{pt: 5, pb: 25,}}>
                    <Accordion sx={{background: 'transparent'}} elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{
                            background: 'transparent', borderRadius: '20px',
                            borderTopLeftRadius: "255px 15px",
                            borderTopRightRadius: "15px 225px",
                            borderBottomRightRadius: "225px 15px",
                            borderBottomLeftRadius: "15px 255px",
                            border: "solid 3px black",
                        }}>
                            <Typography variant={"h4"} fontWeight={"bold"}>FLORIDA</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{background: 'transparent'}}>
                            <Box sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: {xs: 'center', sm: 'space-around'},
                                width: '100%',
                            }}>
                                <LocationSticky name={"Prodigy at Creekside"} address1={"14044 US-301 South"} address2={"Riverview, FL 33578"} phone={"(813) 381-5985"} email={"creekside@prodigylearn.com"} />
                                <LocationSticky name={"Prodigy at Epperson"} address1={"7772 Avery Scope View"} address2={"Wesley Chapel, FL 33545"} phone={"(813) 733-0515"} email={"epperson@prodigylearn.com"} />
                                <LocationSticky name={"Prodigy at Keys Gate"} address1={"2000 SE 28 Avenue"} address2={"Homestead, FL 33035"} phone={""} email={"keysgate@prodigylearn.com"} />
                                <LocationSticky name={"Prodigy at Tapestry"} address1={"2410 W Carroll St"} address2={"Kissimmee, FL 34741"} phone={"(407) 749-6328"} email={"tapestry@prodigylearn.com"} />
                                <LocationSticky name={"Prodigy at Winthrop"} address1={"6390 Scholars Hill Ln"} address2={"Riverview, FL 33578"} phone={"(813) 565-8366"} email={"winthrop@prodigylearn.com"} />
                                <LocationSticky name={"Prodigy at Wellen Park"} address1={"11511 Mezzo Drive"} address2={"Venice, FL 34293"} phone={""} email={"wellenpark@prodigylearn.com"} />
                                <LocationSticky name={"Prodigy at Wellington"} address1={"5415 Morning Mist Way"} address2={"Lake Worth, FL 33449"} phone={"(561) 966-2646"} email={"wellington@prodigylearn.com"} />
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={{background: 'transparent', mt:3,}} elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{
                            background: 'transparent', borderRadius: '20px',
                            borderTopLeftRadius: "255px 15px",
                            borderTopRightRadius: "15px 225px",
                            borderBottomRightRadius: "225px 15px",
                            borderBottomLeftRadius: "15px 255px",
                            border: "solid 3px black",
                        }}>
                            <Typography variant={"h4"} fontWeight={"bold"}>LOUISIANA</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: {xs: 'center', sm: 'space-around'},
                                width: '100%',
                            }}>
                                <LocationSticky name={"Prodigy at Acadiana"} address1={"2520 Chemin Metairie Parkway"} address2={"Youngsville, LA 70592"} phone={"(337) 399-0064"} email={"acadiana@prodigylearn.com"} />
                              </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={{background: 'transparent', mt:3,}} elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{
                            background: 'transparent', borderRadius: '20px',
                            borderTopLeftRadius: "255px 15px",
                            borderTopRightRadius: "15px 225px",
                            borderBottomRightRadius: "225px 15px",
                            borderBottomLeftRadius: "15px 255px",
                            border: "solid 3px black",
                        }}>
                            <Typography variant={"h4"} fontWeight={"bold"}>SOUTH CAROLINA</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: {xs: 'center', sm: 'space-around'},
                                width: '100%',
                            }}>
                                <LocationSticky name={"Prodigy at Berkeley"} address1={"106 Bee Tree Blvd"} address2={"Summerville, SC 29486"} phone={"(843)-970-3382"} email={"berkeley@prodigylearn.com"} />
                              </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>

                <ContactFooter/>
                <Footer/>
            </Box>


        </>
    );
}

export default OurSchools
