import {Box, Link, Typography} from "@mui/material";
import flare from "../assets/flare/rip-footer.svg";
import facebook from "../assets/social/facebook.svg";
import instagram from "../assets/social/instagram.svg";

function Footer() {
    return (
        <>
            <Box sx={{backgroundColor: '#f0e3d7',}}>
                <img style={{
                    width: '100vw',
                    objectFit: 'cover',
                    maxHeight: 125,
                    objectPosition: 'top',
                }} src={flare} alt={"ripped paper"}/>
            </Box>
            <Box sx={{
                backgroundColor: '#3A1F0C', minHeight: 200, color: 'white', pb: 1,
            }}>
                <Box className={'footer'} sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', }}>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: {xs: 'center', sm: 'start'}, mt: 5, width: 200,}}>
                        <Link sx={{fontWeight: 'bold'}}>Our Story</Link>
                        <Link>Our Approach</Link>
                        <Link>Learning Environment</Link>
                        <Link>Family Engagement</Link>
                        <Link>Accreditation</Link>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: {xs: 'center', sm: 'start'}, mt: 5, width: 200,}}>
                        <Link sx={{fontWeight: 'bold'}}>Academics</Link>
                        <Link>Curriculum</Link>
                        <Link>Nido</Link>
                        <Link>Preschool</Link>
                        <Link>Primary</Link>
                        <Link>Scaffolded Groups</Link>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: {xs: 'center', sm: 'start'}, mt: 5, width: 200,}}>
                        <Link sx={{fontWeight: 'bold'}}>Arts & Labs</Link>
                        <Link>Art Lab</Link>
                        <Link>Culinary Lab</Link>
                        <Link>Language Immersion</Link>
                        <Link>Mindfulness</Link>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: {xs: 'center', sm: 'start'}, mt: 5, width: 200,}}>
                        <Link>Admissions</Link>
                        <Link>Our Schools</Link>
                        <Link>Let's Talk</Link>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', mt:4,}}>
                    {/*<Box component={"img"} sx={{width:300,}} src={logo}/>*/}
                    <Box>
                        <Typography variant="h5" sx={{fontWeight:'bold', mb:1,}}>Stay Connected</Typography>
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center',}}>
                            <Link href={"https://facebook.com/prodigylearn"}><Box component={"img"} src={facebook} sx={{width:30,}}></Box></Link>
                            <Box sx={{width:30,}}/>
                            <Link href={"https://instagram.com/prodigyearlylearnusa"}><Box component={"img"} src={instagram} sx={{width:30,}}></Box></Link>
                        </Box>

                    </Box>
                </Box>
                <Box sx={{mt: 4,}}>
                    <Typography variant={"caption"}>© {(new Date()).getFullYear()} PRODIGY LEARNING, INC.</Typography>

                </Box>
            </Box>
        </>
    );
}

export default Footer
